// standard packages
import React, { useState, useEffect } from 'react'

// import styles
import styles from './data.module.scss'

// import 3rd party packages
import classNames from 'classnames'
import { Link } from 'gatsby'

// import components
import { Export, Indicator } from '../../misc/Queries'
import { LoadingSpinner } from '../../common'

// common components
import * as common from '../../common'
import { getInitCap, isEmpty, areaScorecardDefs } from '../../misc/Util'

// assets
import downloadSvg from '../../../assets/icons/download.svg'

const Data = ({
  // set page function allows current page to be set so nav emphasis is applied
  setPage,
  regions = [],
  indicators = [],
  placeNamesByRegion = [],
  indicatorNameMap,
}) => {
  // STATE // ---------------------------------------------------------------//
  const [filters, setFilters] = useState({})
  // const [filters, setFilters] = useState({
  //   'place.name': ['Afghanistan'],
  //   level: [0.5],
  //   title: [56],
  // })
  const [year, setYear] = useState('recent')
  const [hasMounted, setHasMounted] = useState(false)
  // set default ordering to be by country name, secondary sort by policy name
  const [ordering, setOrdering] = useState([
    ['date_time.datetime', 'desc'],
    ['is_recent', 'desc'],
    ['title2', 'asc'],
    ['place.name', 'asc'],
  ])
  // flag for whether the download button should say loading or not
  const [buttonLoading, setButtonLoading] = useState(false)
  const [table, setTable] = useState(null)
  const [curPage, setCurPage] = useState(1)
  const [pagesize, setPagesize] = useState(10)
  const [loading] = useState(false)

  // CONSTANTS // -----------------------------------------------------------//
  // data structure is as follows:
  // {
  //     "value": 1,
  //     "code": "CT1",
  //     "title": "Treatment Initiation",
  //     "descrip": "Do national guidelines on when to initiate HIV treatment align with international recommendations?",
  //     "level": "adopted",
  //     "year": "2020",
  //     "id": 2640,
  //     "has_subs": false,
  //     "type": "Indicator",
  //     "iso2": "af",
  //     "place_name": "Afghanistan",
  //     "region": "Other region",
  //     "is_pepfar": false,
  //     "display": false
  // }

  // get indicator items for filter definitions
  const indicatorOptions = indicators.map(({ value, name }) => {
    return { value, label: name, id: value }
  })

  const countries = [
    ...new Set(
      placeNamesByRegion
        .filter(d => {
          if (filters['region'] !== undefined) {
            return filters['region'].includes(d.region)
          } else return true
        })
        .map(d => d.place_name)
    ),
  ].filter(d => d)

  // define filter data fields, labels, and possible values (items)
  // for <Table />
  const filterDefs = [
    {
      region: {
        field: 'region',
        label: 'UNAIDS region',
        items: regions.map(name => {
          return { value: name, label: name, id: name }
        }),
      },
      'place.name': {
        field: 'place.name',
        label: 'Country',
        items: countries.map(name => {
          return { value: name, label: name, id: name }
        }),
      },
      is_pepfar: {
        field: 'is_pepfar',
        label: 'PEPFAR country?',
        items: [
          { value: 'Yes', label: 'Yes', id: true },
          { value: 'No', label: 'No', id: false },
        ],
      },
    },
    {
      area: {
        field: 'area',
        label: 'Policy category',
        items: areaScorecardDefs.map(({ area }) => {
          return {
            // force Clinical and Treatment to sort correctly
            value:
              area === 'Clinical and treatment' ? 'Clinical / treatment' : area,
            label: area,
            id: area,
          }
        }),
      },
      title: {
        field: 'title',
        label: 'Policy name',
        items: indicatorOptions,
      },
      level: {
        field: 'level',
        label: 'Adoption level',
        items: [
          ['Adopted', 1.0],
          ['Partially adopted', 0.5],
          ['Not adopted', 0.0],
          ['No data', -1.0],
        ].map(([name, value]) => {
          return { value, label: name, id: value }
        }),
      },
    },
  ]

  // UTILITY FUNCTIONS // ---------------------------------------------------//
  const updateTable = async () => {
    // setLoading(true)
    // if `title` is in the filters: convert allowed values from id to title
    // and use a special filter array for `title`
    const debugData = await Indicator({
      ordering,
      filters: {
        ...filters,
        year: [formatYearFilterValue(year)],
      },
      page: curPage,
      pagesize,
    })
    const data = debugData.data
    const nTotalRecords = debugData.n
    const filteredData = data.filter(d => d.type !== 'Subindicator')
    const children = data.filter(d => d.type === 'Subindicator')

    // columns
    const columns = [
      {
        dataField: 'place.name',
        header: 'Country',
        sort: true,
        formatter: function formatter(cell, row) {
          return <Link to={`/${row.iso2.toLowerCase()}`}>{cell}</Link>
        },
      },
      {
        dataField: 'title',
        header: 'Policy name',
        sort: true,
        sortValue: (cell, row) => {
          return indicatorNameMap[row.code]
        },
        formatter: function formatter(cell, row) {
          return (
            <>
              {indicatorNameMap[row.code]}:{' '}
              <b>
                {row.level === null ? 'No data for ' : row.level + ' in '}
                {row.year}
              </b>
            </>
          )
        },
      },
      {
        dataField: 'title2',
        header: 'Policy name',
        sort: true,
        sortValue: (cell, row) => {
          return indicatorNameMap[row.code]
        },
        formatter: function formatter(cell, row) {
          return <>{indicatorNameMap[row.code]}</>
        },
      },
      {
        dataField: 'level',
        sortField: 'value',
        header: 'Adoption level',
        sort: true,
        formatter: function formatter(v) {
          const formatted = getInitCap(v)
          return formatted !== null && formatted !== undefined ? (
            formatted
          ) : (
            <span className={styles.unspecified}>{'No data'}</span>
          )
        },
      },
      { dataField: 'date_time.datetime', header: 'Year', sort: true },
    ]

    /**
     * Updates ordering state, renaming some fields and adding some fields if
     * necessary for API request.
     * @param {string[][]} newOrdering The new ordering to potentially be
     * modified
     * @returns {string[][]} An ordering readable by the API server code
     */
    const handleOrderingChange = newOrdering => {
      // always secondary sort by Policy name col (title2)
      const alreadySortedByTitle = newOrdering.some(
        ord => ord[0] === 'title2' || ord[0] === 'title'
      )
      const alreadySortedByPlace = newOrdering.some(
        ord => ord[0] === 'place.name'
      )
      const alreadySortedByYear = newOrdering.some(
        ord => ord[0] === 'date_time.datetime'
      )
      const updateNeeded =
        !alreadySortedByTitle || alreadySortedByYear || !alreadySortedByPlace
      if (!updateNeeded) {
        setOrdering(newOrdering)
      } else {
        if (!alreadySortedByTitle)
          newOrdering = [['title2', 'asc'], ...newOrdering]
        if (!alreadySortedByPlace)
          newOrdering = [['place.name', 'asc'], ...newOrdering]
        if (alreadySortedByYear) {
          const ord = newOrdering.find(o => o[0] === 'date_time.datetime')
          newOrdering = [...newOrdering, ['is_recent', ord[1]]]
        } else {
          newOrdering = [
            ['date_time.datetime', 'desc'],
            ['is_recent', 'desc'],
            ...newOrdering,
          ]
        }
        setOrdering(newOrdering)
      }
    }
    // return table JSX component
    setTable(
      <common.Table
        {...{
          key: 'table',
          allowExpand: true,
          year,
          children,
          ordering,
          setOrdering: handleOrderingChange,
          className: 'bandedStandard',
          noun: 'records',
          columns,
          data: filteredData,
          indicatorNameMap,
          curPage,
          setCurPage,
          pagesize,
          setPagesize,
          nTotalRecords,
          useApiPagination: true,
        }}
      />
    )
    // setLoading(false)
  }

  // EFFECT HOOKS // --------------------------------------------------------//
  useEffect(() => {
    setPage('data')
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (curPage !== 1) setCurPage(1)
    else updateTable()
  }, [year, filters, pagesize])

  useEffect(() => {
    updateTable()
  }, [ordering, curPage])

  return (
    <>
      {hasMounted && (
        <div className={styles.data}>
          {
            <React.Fragment>
              <common.Drawer
                {...{
                  label: (
                    <>
                      <h2>POLICY DATA</h2>
                      {DownloadBtn({
                        buttonLoading,
                        setButtonLoading,
                        render: true,
                        class_name: 'all_static',
                        filters: {
                          ...filters,
                          year: [formatYearFilterValue(year)],
                        },
                        disabled: false,
                        message: (
                          <React.Fragment>
                            <span>
                              Download {isEmpty(filters) ? '  ' : 'filtered'}{' '}
                              data
                            </span>
                          </React.Fragment>
                        ),
                      })}
                    </>
                  ),
                  noCollapse: false,
                  content: (
                    <React.Fragment>
                      {
                        <>
                          <div className={styles.mobileOnly}>
                            <common.TimeframePicker
                              {...{ year, setYear, allowAll: true }}
                            />
                          </div>
                          <div>
                            <i>Select filters to apply to data.</i>
                            <i className={styles.mobileOnly}>
                              &nbsp;Visit this page using a desktop browser to
                              download data.
                            </i>
                            {Object.keys(filters).length > 0 && (
                              <>
                                &nbsp;
                                <button onClick={() => setFilters({})}>
                                  Clear filters
                                </button>
                              </>
                            )}
                          </div>
                          <div className={styles.yearPicker}>
                            <common.TimeframePicker
                              {...{ year, setYear, allowAll: true }}
                            />
                          </div>
                          <common.FilterSet
                            {...{
                              filterDefs,
                              filters,
                              setFilters,
                              noToggle: true,
                            }}
                          />
                        </>
                      }
                    </React.Fragment>
                  ),
                }}
              />
              {table}
              {!table && <div style={{ height: '900px' }} />}
            </React.Fragment>
          }
        </div>
      )}
      <LoadingSpinner loading={loading} />
    </>
  )
}

const DownloadBtn = ({
  render,
  message,
  class_name,
  filters,
  disabled,
  buttonLoading,
  setButtonLoading,
}) => {
  return (
    <button
      className={classNames(styles.downloadBtn, {
        [styles.loading]: buttonLoading || disabled,
        [styles[class_name]]: true,
      })}
      onClick={e => {
        e.stopPropagation()

        const downloadAllYearsXLSX =
          Object.keys(filters).length === 1 &&
          'year' in filters &&
          filters['year'][0] === 'all' &&
          filters['year'].length === 1
        // download static file for "all years" since it is large
        if (downloadAllYearsXLSX) {
          if (window !== undefined)
            window.location.assign(
              window.location.origin +
                '/HIV Policy Lab - Data Export - All years.xlsx'
            )
          return
        } else {
          setButtonLoading(true)
          Export({
            filters,
            class_name,
            ordering: [
              ['date_time.datetime', 'desc'],
              ['is_recent', 'desc'],
              ['title2', 'asc'],
              ['place.name', 'asc'],
            ],
          })
            .then(() => setButtonLoading(false))
            .catch(e => {
              setButtonLoading(false)
              alert('An error occured while downloading data: ' + e)
            })
        }
      }}
    >
      <img src={downloadSvg} />
      <div>
        {!buttonLoading && render && message}
        {buttonLoading && (
          <React.Fragment>
            <span>Downloading data, please wait...</span>
          </React.Fragment>
        )}
      </div>
    </button>
  )
}

export default Data
/**
 * Formats the input year value for API request filtering, mainly by converting
 * YYYY strings to integers.
 * @param {string | number} year The year filter value.
 * @returns {string | number} The value parsed for API request filtering
 */
function formatYearFilterValue(year) {
  return !['recent', 'all'].includes(year) ? parseInt(year) : year
}
