import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Router } from '@reach/router'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Data from '../components/views/Data/Data'

export default function DataTemplate({
  pageContext: { placeNamesByRegion, regions, indicators, indicatorNameMap },
}) {
  const [page, setPage] = useState(null)
  return (
    <Layout page={page}>
      <SEO
        title="Data"
        description="View and export policy data from HIV Policy Lab"
      />
      <Data
        {...{
          page,
          setPage,
          placeNamesByRegion,
          regions,
          indicators,
          indicatorNameMap,
        }}
      />
    </Layout>
  )
}
